import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

// let token = document.head.querySelector('meta[name="csrf-token"]');
// // if (token) {
// //     axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
// // } else {
// //     console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
// // }

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

import './echo';
